<template>
  <b-modal
    :id="modalid"
    v-model="showModal"
    :hide-header="true"
    :hide-footer="true"
  >
    <h5>
      <span class="warning">
        <font-awesome-icon icon="exclamation-circle" />
      </span>
      Opgelet!
    </h5>
    <p class="my-4">
      {{ message }}
    </p>
    <b-button variant="primary" @click="confirm()">Bevestig</b-button>
    <b-button variant="link" @click="hide()">Annuleren</b-button>
  </b-modal>
</template>

<script>
export default {
  props: {
    message: {
      required: true,
      type: String,
      default: function() {
        return ''
      }
    },
    modalid: {
      required: true,
      type: String,
      default: function() {
        return ''
      }
    },
    show: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showModal: this.show
    }
  },
  watch: {
    show(val) {
      this.showModal = val
    }
  },
  methods: {
    hide: function() {
      this.showModal = false
      this.$emit('hide')
    },
    confirm: function() {
      this.$emit('confirm')
    },
    cancel: function() {
      this.$emit('cancel')
    }
    //this.$refs[this.modalid].show()
  }
}
</script>

<style scss>
.modal-content {
  border: 5px solid #d3732f;
  border-radius: 5px;
}
.warning {
  color: #d3732f;
}
</style>
