const { axiosCreate } = require('./Api')
const { getCart } = require('./CartService')
import store from '../state/store'

async function getReservations({ count = 10, index = 1 } = {}) {
  const result = await axiosCreate.get('reservationorders', {
    params: {
      CustomerID: store.getters.customerID,
      Orderby: 'DateTimeExpectedStart',
      SortReverse: true,
      Size: count,
      index
    },
    headers: { authorization: store.getters.token }
  })

  return result.data
}

async function getReservationItem({ reservationID }) {
  const result = await axiosCreate.get(`reservationorders/${reservationID}`, {
    params: { ContactID: store.getters.contactID },
    headers: { authorization: store.getters.token }
  })

  return result.data
}
async function getReservationItems({ reservationID }) {
  const result = await axiosCreate.get(
    `reservationorders/${reservationID}/items`,
    {
      headers: { authorization: store.getters.token }
    }
  )

  return result.data.Collection
}

async function cancelReservation({ reservationID }) {
  const result = await axiosCreate.post(
    `reservationorders/${reservationID}/cancel`,
    {
      Reason: 'Geannuleerd vanuit gebruikersdashboard'
    },
    {
      params: { ContactID: store.getters.contactID },
      headers: { authorization: store.getters.token }
    }
  )

  return result
}

async function addReservationToCart({ reservationID }) {
  const result = await axiosCreate.post(
    `custReservationOrders/${reservationID}/addToCart/${store.getters.contactID}`,
    {},
    {
      headers: {
        authorization: store.getters.token
      }
    }
  )
  await getCart()
  return result
}

async function updateReservationWithFiles({ formData, reservationID } = {}) {
  const result = await axiosCreate.post(
    `reservationorders/${reservationID}/files`,

    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: store.getters.token
      },
      params: { ContactID: store.getters.contactID }
    }
  )

  if (result.data.Message === 'File already exists') {
    alert(
      'Er bestaat al een bestand met deze naam, het bestand is niet geupload.'
    )
    return 'file-exists'
  }

  return result
}

module.exports = {
  getReservations,
  getReservationItems,
  getReservationItem,
  updateReservationWithFiles,
  cancelReservation,
  addReservationToCart
}
