<template>
  <b-row>
    <b-col cols="12" class="pb-3">
      <b-button variant="outline-primary" @click="chooseFiles()"
        >Selecteer bestand...</b-button
      >
      <b-form-file
        :id="`chooseFiles-${reservationid}-${testimonyType}-${fileType}`"
        v-model="file"
        class="mt-3"
        plain
        hidden
      ></b-form-file>
    </b-col>
    <b-col v-if="file" cols="12"
      ><div class="">
        <i class="mt-3">{{ file ? file.name : '' }}</i>
      </div></b-col
    >
    <b-col v-if="file">
      <b-button variant="primary" @click="uploadFile()"
        >Bestand uploaden
        <font-awesome-icon v-if="uploading" class="fa-spin" far icon="spinner"
      /></b-button>
    </b-col>
  </b-row>
</template>

<script>
import { updateReservationWithFiles } from '@/services/ReservationService'
export default {
  props: {
    reservationid: {
      type: String,
      required: true,
      default: function() {
        return ''
      }
    },
    fileType: {
      type: String,
      required: true,
      default: function() {
        return ''
      }
    },
    testimonyType: {
      type: String,
      required: false,
      default: function() {
        return ''
      }
    }
  },
  data() {
    return {
      file: null,
      uploading: false
    }
  },
  methods: {
    chooseFiles: function() {
      document
        .getElementById(
          `chooseFiles-${this.reservationid}-${this.testimonyType}-${this.fileType}`
        )
        .click()
    },
    uploadFile: async function() {
      this.uploading = true
      let formData = new FormData()

      if (this.file) {
        formData.append(`${this.fileType}-${this.file.name}`, this.file)
        formData.append('CUST_FileType', this.fileType)
        formData.append('CUST_TestimonyNumber', this.testimonyType)
      }

      await updateReservationWithFiles({
        reservationID: this.reservationid,
        formData
      })

      this.$emit('uploaded')
      this.file = null
      this.uploading = false
    }
  }
}
</script>

<style></style>
