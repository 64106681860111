<template>
  <b-container v-if="reservation.DateTimeExpectedStart" class="page-container">
    <Breadcrumbs
      :bread-crumbs="breadCrumbs"
      :currentpage="
        `Details van de ${
          reservation.Status === 'Active' ? 'aanvraag' : 'reservatie'
        } #${reservationID}`
      "
    />
    <h1>
      Details van de
      {{ reservation.Status === 'Active' ? 'aanvraag' : 'reservatie' }} #{{
        reservationID
      }}
    </h1>

    <div v-if="reservation.MissingTestimonies && !reservationCancelled">
      <div v-if="paAttestRequired || riscoAnalyseRequired">
        <b-alert v-if="paAttestRequired" show variant="warning">
          <font-awesome-icon icon="exclamation-circle" />Opgelet: PA-attest is
          vereist voor een product in je winkelmandje!
        </b-alert>
        <b-alert v-if="riscoAnalyseRequired" show variant="warning">
          <font-awesome-icon icon="exclamation-circle" />Opgelet: Een risico
          analyse is vereist voor een product in je winkelmandje!
        </b-alert>
      </div>
    </div>

    <reservation-header :reservation="reservation" />

    <b-row>
      <b-col>
        <b-table :fields="fields" :items="reservationItems" class="mt-4">
          <template v-slot:cell(Price)="data">
            <div class="form-group text-right">
              &euro;
              {{
                (Math.round(data.item.Price * 100) / 100)
                  .toFixed(2)
                  .replace('.', ',')
              }}
            </div>
          </template>
          <template v-slot:cell(TotalExcVAT)="data">
            <div class="form-group text-right">
              &euro;
              {{
                (Math.round(data.item.TotalExcVAT * 100) / 100)
                  .toFixed(2)
                  .replace('.', ',')
              }}
            </div>
          </template>
        </b-table>
        <div class="prices">
          <table class="table text-right">
            <tr>
              <td>
                <strong>Subtotaal:</strong>
              </td>
              <td>
                &euro;
                {{
                  (totalIncVat || 0)
                    .toFixed(2)
                    .toString()
                    .replace('.', ',')
                }}
              </td>
            </tr>
            <tr v-if="adminCosts">
              <td>
                <strong>Administratiekosten:</strong>
              </td>

              <td width="100px">&euro; 10,00</td>
            </tr>
            <tr>
              <td>
                <strong>Totaalbedrag:</strong>
              </td>
              <td>
                &euro;
                {{
                  (reservation.TotalIncVAT || 0)
                    .toFixed(2)
                    .toString()
                    .replace('.', ',')
                }}
              </td>
            </tr>
          </table>
        </div>
        <h4 class="mt-1 mb-2 pb-3">Informatie over het evenement</h4>
        <reservation-information :reservation="reservation" />
        <h4 class="mt-5">Gekoppelde documenten</h4>
        <b-row
          v-if="reservation.ReservationAttachments"
          class="card-h-100 mt-0"
        >
          <b-col sm="12" md="6" lg="4" class="pt-4">
            <b-card class="card-h-100">
              <h5>PA-ATTESTEN</h5>
              <div
                v-if="reservation.ReservationAttachments.PAAttests.length > 0"
              >
                <ul>
                  <li
                    v-for="file in reservation.ReservationAttachments.PAAttests"
                    :key="file.FileName"
                  >
                    <a :href="getAttachmentDownloadURL(file)">{{
                      file.FileName
                    }}</a>
                  </li>
                </ul>
                <hr />
              </div>

              <div class="card-body-bottom">
                <strong>Extra documenten opladen</strong>
                <UploadFile
                  :reservationid="reservation.ReservationID"
                  file-type="Testimony"
                  testimony-type="PA Attest"
                  @uploaded="refreshData"
                />
              </div>
            </b-card>
          </b-col>
          <b-col sm="12" md="6" lg="4" class="pt-4">
            <b-card>
              <h5>RISICO-ANALYSES</h5>

              <div
                v-if="
                  reservation.ReservationAttachments.RisicoAnalyses.length > 0
                "
              >
                <ul>
                  <li
                    v-for="file in reservation.ReservationAttachments
                      .RisicoAnalyses"
                    :key="file.FileName"
                  >
                    <a :href="getAttachmentDownloadURL(file)">{{
                      file.FileName || 'unnamed-file'
                    }}</a>
                  </li>
                </ul>
                <hr />
              </div>

              <div class="card-body-bottom mt-5">
                <strong>Extra documenten opladen</strong>
                <UploadFile
                  :reservationid="reservation.ReservationID"
                  file-type="Testimony"
                  testimony-type="Risico analyse"
                  @uploaded="refreshData"
                />
              </div>
            </b-card>
          </b-col>
          <b-col sm="12" md="6" lg="4" class="pt-4">
            <b-card class="card-h-100">
              <h5>BESTELBONS</h5>
              <div
                v-if="
                  reservation.ReservationAttachments.OrderReceips.length > 0
                "
              >
                <ul>
                  <li
                    v-for="file in reservation.ReservationAttachments
                      .OrderReceips"
                    :key="file.FileName"
                  >
                    <a :href="getAttachmentDownloadURL(file)">{{
                      file.FileName
                    }}</a>
                  </li>
                </ul>
                <hr />
              </div>

              <div class="card-body-bottom">
                <strong>Extra documenten opladen</strong>
                <UploadFile
                  :reservationid="reservation.ReservationID"
                  file-type="Other"
                  @uploaded="refreshData"
                />
              </div>
            </b-card>
          </b-col>
          <b-col v-if="reservationAllowCancel" cols="12">
            <b-card class="mt-5 card-normal">
              <b-row>
                <b-col>
                  <h2 class="pt-2">Annuleren ?</h2>
                </b-col>
                <b-col class="text-right">
                  <b-button variant="primary" @click="show = true"
                    >Bestelling annuleren</b-button
                  >
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col v-if="allowReOrder" class cols="12">
        <b-card class="mt-4">
          <b-row>
            <b-col xs="12" md="9">
              <h2 class="pt-2">Wil je deze items nog eens aanvragen?</h2>
            </b-col>
            <b-col xs="12" md="3" class="text-right">
              <b-button variant="primary" @click="addReservationToCart">
                Opnieuw aanvragen
                <font-awesome-icon
                  v-if="addReservationToCartPending"
                  class="fa-spin"
                  far
                  icon="spinner"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <modal
      modalid="prompt"
      :message="promptMessage"
      :show="show"
      @hide="show = false"
      @confirm="cancelReservation()"
    />
  </b-container>
</template>

<script>
import {
  getReservationItems,
  getReservationItem,
  cancelReservation,
  addReservationToCart
} from '@/services/ReservationService'
import UploadFile from '@/views/Account/UploadFile'
import Breadcrumbs from '@/components/header/BreadCrumbs'
import Modal from '@/components/prompt/modal-prompt.vue'

import dayjs from 'dayjs'
import ReservationInformationVue from '../../components/reservation/ReservationInformation.vue'
import ReservationHeaderVue from '../../components/reservation/ReservationHeader.vue'

import isoWeek from 'dayjs/plugin/isoWeek'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'

dayjs.extend(isoWeek)
dayjs.extend(isSameOrAfter)

export default {
  components: {
    UploadFile,
    Breadcrumbs,
    modal: Modal,
    'reservation-information': ReservationInformationVue,
    'reservation-header': ReservationHeaderVue
  },
  data() {
    return {
      show: false,
      reservationItems: [],
      reservation: {},
      adminCosts: false,
      addReservationToCartPending: false,
      breadCrumbs: [
        { page: 'index', title: 'Uitleendienst' },
        { page: 'account', title: 'Je account' }
      ],
      files: {
        paAttesten: null,
        risicoAnalyses: null,
        bestelbonnen: null
      },
      fields: [
        {
          key: 'ItemDescription',
          label: 'Item'
        },
        {
          key: 'Price',
          label: 'Prijs',
          class: 'text-right'
        },
        {
          key: 'Amount',
          label: 'Aantal',
          class: 'text-center'
        },
        {
          key: 'TotalExcVAT',
          label: 'Totaal',
          class: 'text-right'
        }
      ]
    }
  },
  metaInfo: function() {
    return {
      title: `Reservering ${this.$route.params.reservationID}`,
      titleTemplate: '%s - Vlaams-Brabant Uitleendienst'
    }
  },
  computed: {
    allowReOrder: function() {
      if (this.reservation && this.reservation.PayMethodID) {
        if (this.reservation.PayMethodID === 'PROBLEEMRESERVERING') {
          return false
        }
      }
      return true
    },
    dateTimeExpectedStartdayjs: function() {
      return dayjs(this.reservation.DateTimeExpectedStart, 'YYYY-MM-DD')
    },
    reservationInThreeDaysOrLess: function() {
      let requiredDate = this.dateTimeExpectedStartdayjs

      if (
        requiredDate.isoWeekday() == 1 ||
        requiredDate.isoWeekday() == 2 ||
        requiredDate.isoWeekday() == 3
      ) {
        requiredDate = requiredDate.subtract(4, 'days')
      } else if (
        requiredDate.isoWeekday() == 4 ||
        requiredDate.isoWeekday() == 5
      ) {
        requiredDate = requiredDate.subtract(2, 'days')
      }

      return !requiredDate.isSameOrAfter(dayjs())
    },
    reservationAllowCancel: function() {
      if (this.reservationInThreeDaysOrLess) return false

      if (this.reservation.Status === 'Order') return true
      if (this.reservation.Status === 'Active') return true
      if (this.reservation.Status === 'Actief') return true
      return false
    },
    reservationCancelled() {
      return this.reservation.Status === 'Cancelled'
    },
    totalIncVat: function() {
      if (this.adminCosts) return this.reservation.TotalIncVAT - 10.0
      return this.reservation.TotalIncVAT
    },

    reservationID: function() {
      return this.$route.params.reservationID
    },
    hostURL: function() {
      return process.env.VUE_APP_URL
    },
    promptMessage: function() {
      return `Weet je zeker dat je ${
        this.reservation.Status === 'Active' ? 'aanvraag' : 'bestelling'
      } #${this.reservationID} wilt annuleren?`
    },
    paAttestRequired: function() {
      if (this.reservation.MissingTestimonies) {
        return (
          this.reservation.MissingTestimonies.filter(
            testimony => testimony.Number === 'PA Attest'
          ).length > 0
        )
      } else {
        return false
      }
    },

    riscoAnalyseRequired: function() {
      if (this.reservation.MissingTestimonies) {
        return (
          this.reservation.MissingTestimonies.filter(
            testimony => testimony.Number === 'Risico analyse'
          ).length > 0
        )
      } else {
        return false
      }
    }
  },
  created: async function() {
    this.reservation = await getReservationItem({
      reservationID: this.reservationID
    })
    if (this.reservation === '') {
      this.$router.push({ name: '401' })
    }
    this.reservationItems = await getReservationItems({
      reservationID: this.reservationID
    })
    this.adminCosts = this.reservationItems.find(
      reservationItem => reservationItem.ItemID === 'ADMIN'
    )

    this.reservationItems = this.reservationItems.filter(function(obj) {
      return obj.ItemDescription !== 'Administratiekosten'
    })
  },

  methods: {
    getAttachmentDownloadURL: function(file) {
      const attachmentID =
        file.ReservationAttachmentID ?? file.OrderAttachmentID
      return `${this.hostURL}/api/v1/custreservationorders/${this.reservation.ReservationID}/files/${attachmentID}/${this.reservation.AttachmentToken}`
    },
    addReservationToCart: async function() {
      this.addReservationToCartPending = true
      const result = await addReservationToCart({
        reservationID: this.reservationID
      })

      if (result.data === 'OK') {
        this.$router.push({
          name: 'cart',
          query: { addedReservationItems: true }
        })
      }
      this.addReservationToCartPending = false
    },
    refreshData: async function() {
      this.reservation = await getReservationItem({
        reservationID: this.reservationID
      })
    },
    cancelReservation: async function() {
      await cancelReservation({ reservationID: this.reservationID })
      this.$router.push({
        name: 'account',
        query: {
          infoMessage: `${
            this.reservation.Status === 'Active' ? 'Aanvraag' : 'Bestelling'
          } #${this.reservationID} werd succesvol geannuleerd`
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
h1 {
  font-size: 50px;
}
h4 {
  font-size: 35px;
}
p,
strong,
.table {
  font-size: 18px;
}

.card.card-normal.mt-5 {
  height: auto !important;
  .card-body {
    padding-bottom: 20px !important;
  }
}
.card-h-100 .card {
  height: 100% !important;

  .card-body {
    padding-bottom: 200px;
  }
  .card-body-bottom {
    position: absolute;
    bottom: 20px;
  }
}
.prices {
  padding-right: 0px;
  .table {
    background-color: transparent;
    border: none;
    tr,
    td {
      border: none !important;
      font-size: 16px;
      strong {
        font-family: 'DecimaPro-Bold', sans-serif;
      }
    }
  }
}
ul {
  list-style: none;
  padding-left: 0px;
  li {
    a {
      color: black;
      font-size: 18px;
      font-family: 'DecimaPro-Bold', sans-serif;
    }
  }
}
</style>
